import styled from 'styled-components';
import { font } from '../../../styles/atoms/typography';
import { colors } from '../../../styles/atoms/colors';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import shadow from '../../../styles/atoms/shadows';

export const Container = styled.div`
  width: 100%;
  max-width: 770px;
  background: ${colors.base.white};
  ${shadow('xxl')}
  border-radius: 12px;
  overflow: hidden;
  margin: 0px auto;
  ${atMinWidth.lg`
   max-width: 100%;
`}
`;
export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 430px;
  display: flex;
  flex-direction: column;
  ${atMinWidth.lg`
   display: grid;
   grid-template-columns: 470px 1fr;
`}
`;
export const Thumbnail = styled.div`
  width: 100%;
  height: 328px;
  ${atMinWidth.sm`
   height: 470px;
`}
  ${atMinWidth.lg`
   height: 100%
`}
.gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;
export const Text = styled.div`
width: 100%;
padding: 24px 48px;
display: flex;
flex-direction: column;
justify-content: center;
gap: 24px;
.case-study-heading {
  ${font('display', 'lg', '700')}
  color: ${colors.gray[900]};
  ${atMinWidth.sm`
      ${font('display', 'xl', '700')}
  `}
}
.case-study-subhead {
  ${font('text', 'lg', '400')}
  color: ${colors.gray[600]};
  ${atMinWidth.sm`
     ${font('text', 'xl', '400')}
  `}
}
.case-study-link {
  ${font('text', 'lg', '700')}
  color: ${colors.primary[500]};
  svg {
      margin-left: 8px;
      transition: all 250ms ease-in-out;
  }
  &:hover {
      text-decoration: none;
      svg {
          transform: translateX(6px);
      }
  }
}
`;
